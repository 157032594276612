import axios from "axios";
import authHeader from "./auth-header";

const API_URL = "http://117.206.152.235:8081/projects/ws_rtm/public/api/";

const getPublicContent = () => {
  return axios.get(API_URL + "all");
};

const getLatestData = (offset, limit) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const role =  user.user.role;
  return axios.post(
    API_URL + "latestdata",
    {
      offset,
      limit,
      role,
    },
    { headers: authHeader() }
  );
};

const getDataView = (from_date, to_date, site_id) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const role =  user.user.role;
  return axios.post(
    API_URL + "history",
    { from_date, to_date, site_id, role },
    { headers: authHeader() }
  );
};

const getTotFlow = (from_date, to_date, site_id) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const role =  user.user.role;
  return axios.post(
    API_URL + "totflow",
    { from_date, to_date, site_id, role },
    { headers: authHeader() }
  );
};

const getBillView = (from_date, to_date, site_id) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const role =  user.user.role;
  return axios.post(
    API_URL + "billview",
    { from_date, to_date, site_id, role },
    { headers: authHeader() }
  );
};

const getSites = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const role =  user.user.role;
  return axios.post(API_URL + "sites", {role}, { headers: authHeader() });
};

const getSitesDetails = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const role =  user.user.role;
  return axios.post(API_URL + "sitedetails", {role}, { headers: authHeader() });
};

const forgotPassword = (email) => {
  return axios.post(API_URL + "forgotPass", { headers: authHeader() });
};

const ChangePassword = (old_password, new_password, confirm_password) => {
  return axios.post(
    API_URL + "change_password",
    { old_password, new_password, confirm_password },
    { headers: authHeader() }
  );
};

const userObject = {
  getPublicContent,
  getLatestData,
  getDataView,
  getSites,
  forgotPassword,
  ChangePassword,
  getBillView,
  getTotFlow,
  getSitesDetails
};
export default userObject;
