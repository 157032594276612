import React, { Component } from "react";
import PropTypes from "prop-types";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses,
} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { Navigate } from "react-router-dom";
import UserService from "../services/user.service";
import Copyright from "./footer";
import BasicBreadcrumbs from "./breadcrumbs";
import Modal from '@material-ui/core/Modal';
import Typography from '@mui/material/Typography';

function QuickSearchToolbar(props) {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
        justifyContent: "space-between",
        display: "flex",
        alignItems: "flex-start",
        flexWrap: "wrap",
      }}
    >
      <div>
        <GridToolbarContainer className={gridClasses.toolbarContainer}>
          <GridToolbarExport />
        </GridToolbarContainer>
      </div>
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? "visible" : "hidden" }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
        sx={{
          width: {
            xs: 1,
            sm: "auto",
          },
          m: (theme) => theme.spacing(1, 0.5, 1.5),
          "& .MuiSvgIcon-root": {
            mr: 0.5,
          },
          "& .MuiInput-underline:before": {
            borderBottom: 1,
            borderColor: "divider",
          },
        }}
      />
    </Box>
  );
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      rowsPerPage: 150,
      data: [],
      count: 0,
      isLoading: false,
      searchText: "",
      result: [],
    };
    this.requestSearch = this.requestSearch.bind(this);
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
    this.fetchData(newPage, this.state.rowsPerPage);
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value });
    this.setState({ page: 0 });
    this.fetchData(0, event.target.value);
  };

  requestSearch = (searchValue) => {
    this.setState({ searchText: searchValue });
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    let data = this.state.data,
      result = [];
    result = data.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field].toString());
      });
    });
    this.setState({ result: result });
  };

  fetchData = (offset, limit) => {
    this.setState({ isLoading: true });
    UserService.getLatestData(offset, limit).then(
      (response) => {
        this.setState({
          data: response.data.LatestData,
          result: response.data.LatestData,
          page: this.state.page,
          count: response.data.count,
          isLoading: false,
        });
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        this.setState({
          data: _content,
          page: this.state.page,
          isLoading: false,
        });
      }
    );
    this.setState({ isLoading: true });
  };

  componentDidMount() {
    this.fetchData(this.state.page, this.state.rowsPerPage);
  }

  render() {
    if (!localStorage.getItem("isLoggedIn")) {
      // if true the user will be redirected to /dashboard
      return <Navigate to={{ pathname: "/" }} />;
    }
    // const StyledTableCell = styled(TableCell)(({ theme }) => ({
    //   [`&.${tableCellClasses.head}`]: {
    //     backgroundColor: theme.palette.common.black,
    //     color: theme.palette.common.white,
    //   },
    //   [`&.${tableCellClasses.body}`]: {
    //     fontSize: 15,
    //   },
    // }));

    // const StyledTableRow = styled(TableRow)(({ theme }) => ({
    //   "&:nth-of-type(odd)": {
    //     backgroundColor: theme.palette.action.hover,
    //   },
    //   // hide last border
    //   "&:last-child td, &:last-child th": {
    //     border: 0,
    //   },
    // }));
    const { result, searchText, isLoading } = this.state;
    const columns = [
      //{ field: "Zone", headerName: "Zone", width: 70 },
      /*{ field: "siteid", headerName: "SiteID", width: 70, headerClassName: {},
       renderCell: (params) => {
        const onClickDelete = async () => {
          //return alert(JSON.stringify(params.row, null, 4));
        };
        return (
          <>
            <a color="secondary" href={() => false} onClick={onClickDelete}>
            {params.row.siteid}
            </a>
          </>
        );
      },
    },    */        
      { field: "cLocation", headerName: "Z/SiteID/Location.", width: 150,
      renderCell: (params) => {
        return (
          <div style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>
            <Typography variant="body2">{ params.row.siteid} </Typography>
            <Typography variant="body2">{ params.row.cLocation} </Typography>
            </div>
        )
      }},
      { field: "dt", headerName: "DateTime", width: 150 },
      {
        field: "FL",
        headerName: "Flow",
        type: "number",
        width: 80,
      },
      {
        field: "TOTP",
        headerName: "TOT Flow(m3)",
        type: "number",
        width: 120,
      },
      { field: "RestirictedTime", headerName: "Res. Time", width: 100 },
      { field: "ExceeedsFlowPerHr", headerName: "Exceeds Flow", width: 120 },
      { field: "Delayed", headerName: "> 24 Hrs", width: 100 },
      { field: "DischargeFromToTime", headerName: "Disch. F/T", width: 100 },
      { field: "CommittedM3DayHr", headerName: "Com Qt.M3Day/Hr", width: 150 }
    ];

    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    };
    
    /*const rows = [
      { id: 1, lastName: "Snow", firstName: "Jon", age: 35 },
      { id: 2, lastName: "Lannister", firstName: "Cersei", age: 42 },
      { id: 3, lastName: "Lannister", firstName: "Jaime", age: 45 },
      { id: 4, lastName: "Stark", firstName: "Arya", age: 16 },
      { id: 5, lastName: "Targaryen", firstName: "Daenerys", age: null },
      { id: 6, lastName: "Melisandre", firstName: null, age: 150 },
      { id: 7, lastName: "Clifford", fi rstName: "Ferrara", age: 44 },
      { id: 8, lastName: "Frances", firstName: "Rossini", age: 36 },
      { id: 9, lastName: "Roxie", firstName: "Harvey", age: 65 },
    ];
    console.log(rows);*/
    // Always a good thing to check if the data is loaded
    return (
      <Container disableGutters maxWidth="false" sx={{ mt: 0, mb: 4 }}>
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <BasicBreadcrumbs name="Latest Data" />
        <Grid container spacing={3}>
          {/* Recent Orders */}
          <Grid item xs={12}>
            <div style={{ width: "100%" }}>
              <DataGrid
                density="compact"
                getRowHeight={() => 'auto'}
                rowHeight={120}
                autoHeight
                getRowId={(result) => result.siteid}
                rows={result}
                columns={columns}
                pageSize={100}
                rowsPerPageOptions={[100]}
                components={{
                  Toolbar: QuickSearchToolbar,
                }}
                componentsProps={{
                  toolbar: {
                    value: searchText,
                    onChange: (event) => this.requestSearch(event.target.value),
                    clearSearch: () => this.requestSearch(""),
                  },
                }}
              />
            </div>
          </Grid>
        </Grid>
        <Copyright sx={{ pt: 4 }} />
        <Modal
        open={false}
        onClose={false}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography>
        </Box>
      </Modal>
      </Container>
    );
  }
}
