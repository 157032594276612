import React from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
//import AuthService from "../services/auth.service";
import Copyright from "./footer";
import Box from "@mui/material/Box";
import Logo from "../assets/vecl-logo.png";

const Home = () => {
  //const currentUser = AuthService.getCurrentUser();
  return (
    <Container disableGutters maxWidth="false" sx={{ mt: 0, mb: 4 }}>
      <Grid container spacing={3}>
        {/* Chart */}
        <Grid item xs={12} textAlign={"center"}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: 500,
            }}
          >
            <Typography component="h1" variant="h2">
              <Box
                component="img"
                sx={{
                  width: 250,
                }}
                alt="Your logo."
                src={Logo}
              />
            </Typography>

            <Typography component="h1" variant="h2">
              FLOW
            </Typography>
            <Typography component="h1" variant="h2">
              MONITORING PORTAL
            </Typography>
          </Paper>
        </Grid>
        {/* Recent Deposits */}
        {/* <Grid item xs={12} md={4} lg={3}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: 240,
            }}
          ></Paper>
        </Grid> */}
        {/* Recent Orders */}
        {/* <Grid item xs={12}>
          <Paper
            sx={{ p: 2, display: "flex", flexDirection: "column" }}
          ></Paper>
        </Grid> */}
      </Grid>
      <Copyright sx={{ pt: 4 }} />
    </Container>
    /*<Container component="main" maxWidth="md">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h2">
          EFFLUENT 
        </Typography>
        <Typography component="h1" variant="h2">
          MONITORING SYSTEM
        </Typography>
      </Box>
    </Container>*/
  );
};

export default Home;
