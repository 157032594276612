import * as React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import HomeIcon from '@mui/icons-material/Home';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import BarChartIcon from "@mui/icons-material/BarChart";
import LegendToggleIcon from '@mui/icons-material/LegendToggle';
import AssignmentIcon from "@mui/icons-material/Assignment";
import LogoutIcon from '@mui/icons-material/Logout';
import { Link } from "react-router-dom";
import AuthService from "../services/auth.service";

const logout = () => {
  AuthService.logout();
  window.location.href ='/';
};

export const mainListItems = (
  <div>
    <ListItem button component={Link} to="/home">
      <ListItemIcon>
        <HomeIcon />
      </ListItemIcon>
      <ListItemText primary="Home" />
    </ListItem>
    <ListItem button component={Link} to="/latestdata">
      <ListItemIcon>
        <StackedLineChartIcon />
      </ListItemIcon>
      <ListItemText primary="Latest Data" />
    </ListItem>
    <ListItem button component={Link} to="/sitelist">
      <ListItemIcon>
        <CloseFullscreenIcon />
      </ListItemIcon>
      <ListItemText primary="Status" />
    </ListItem>
    <ListItem button component={Link} to="/dataview">
      <ListItemIcon>
        <BarChartIcon />
      </ListItemIcon>
      <ListItemText primary="Data View" />
    </ListItem>
    <ListItem button component={Link} to="/totflow">
      <ListItemIcon>
        <LegendToggleIcon />
      </ListItemIcon>
      <ListItemText primary="Total Flow" />
    </ListItem>
  </div>
);

export const secondaryListItems = (
  <div>
    <ListSubheader inset>Other Menu</ListSubheader>
    <ListItem button component={Link} to="/billview">
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Bill View" />
    </ListItem>
    <ListItem button component={Link} to="/sitedetails">
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Site Details" />
    </ListItem>
    { localStorage.getItem("isLoggedIn") ? (
    <ListItem button component={Link} to="/changepass">
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Change Password" />
    </ListItem>
    ) : (
      null
    )}
    { localStorage.getItem("isLoggedIn") ? (
      <ListItem button onClick={logout}>
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItem>
    ) : (
      null
    )}
  </div>
);
