import React, { Component } from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import { Navigate } from "react-router-dom";
import UserService from "../services/user.service";
import Copyright from "./footer";
import BasicBreadcrumbs from "./breadcrumbs";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      isLoading: false,
    };
  }

  fetchSiteData = () => {
    this.setState({ isLoading: true });
    UserService.getSites().then(
      (response) => {
        this.setState({
          data: response.data.sites,
          isLoading: false,
        });
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        this.setState({
          data: _content,
          isLoading: false,
        });
      }
    );
    this.setState({ isLoading: true });
  };

  componentDidMount() {
    this.fetchSiteData();
  }

  render() {
    if (!localStorage.getItem("isLoggedIn")) {
      // if true the user will be redirected to /dashboard
      return <Navigate to={{ pathname: "/" }} />;
    }

    const Item = styled(Paper)(({ theme }) => ({
      ...theme.typography.body2,
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    }));

    const { data, isLoading } = this.state;
    const green = 'Live(< 1H)';
    const yellow = 'Delay(< 24H)';
    const red = 'No Data(> 24H)';
    // Always a good thing to check if the data is loaded
    return (
      <Container disableGutters maxWidth="false" sx={{ mt: 0, mb: 4 }}>
        <BasicBreadcrumbs name="Company Status" />
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid spacing={1} container mt={1} justifyContent="flex-end">
          <Stack spacing={2} direction="row">
            <Button
              style={{
                backgroundColor: "green",
              }}
              variant="fab"
            >
              {green}
            </Button>
            <Button
              style={{
                backgroundColor: "orange",
              }}
              variant="contained"
            >
              {yellow}
            </Button>
            <Button
              style={{
                backgroundColor: "red",
              }}
              variant="contained"
            >
              {red}
            </Button>
          </Stack>
        </Grid>
        <Grid container mt={1} spacing={1}>
          {/* Recent Orders */}
          <Grid item xs={12}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid
                container
                spacing={{ xs: 1, md: 1 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                {data &&
                  data.map((row, index) => (
                    <Grid item xs={2} sm={4} md={4} key={index}>
                      <Item
                        style={{ backgroundColor: row.Status, color: "white" }}
                      >
                        {row.cLocation} - {row.csiteid}
                      </Item>
                    </Grid>
                  ))}
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Copyright sx={{ pt: 4 }} />
      </Container>
    );
  }
}
