import React, { Component } from "react";
import PropTypes from "prop-types";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses,
} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { Navigate } from "react-router-dom";
import UserService from "../services/user.service";
//import BasicDatePicker from "./datepicker";
import Copyright from "./footer";
import BasicBreadcrumbs from "./breadcrumbs";

function QuickSearchToolbar(props) {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
        justifyContent: "space-between",
        display: "flex",
        alignItems: "flex-start",
        flexWrap: "wrap",
      }}
    >
      <div>
        <GridToolbarContainer className={gridClasses.toolbarContainer}>
          <GridToolbarExport />
        </GridToolbarContainer>
      </div>
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? "visible" : "hidden" }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
        sx={{
          width: {
            xs: 1,
            sm: "auto",
          },
          m: (theme) => theme.spacing(1, 0.5, 1.5),
          "& .MuiSvgIcon-root": {
            mr: 0.5,
          },
          "& .MuiInput-underline:before": {
            borderBottom: 1,
            borderColor: "divider",
          },
        }}
      />
    </Box>
  );
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}
export default class SiteDetails extends Component {
  constructor(props) {
    super(props);

    var today = new Date();

    this.state = {
      data: [],
      site_data: [],
      from_date: today,
      to_date: today,
      site_id: "",
      isLoading: false,
      searchText: "",
      result: [],
    };
    this.handleDropDownChange = this.handleDropDownChange.bind(this);
    this.requestSearch = this.requestSearch.bind(this);
    this.handleStartDate = this.handleStartDate.bind(this);
    this.handleEndDate = this.handleEndDate.bind(this);
  }

  handleChangeDate = (event, newPage) => {
    this.setState({ from_date: newPage });
  };

  handleDropDownChange(event) {
    this.setState({
      site_id: event.target.value,
    });
  }

  handleGridData = (event) => {
    if (this.state.site_id !== "") {
      this.fetchData();
    } else {
      alert("Please select site.");
    }
  };
  handleStartDate = (frmdate) => {
    this.setState({
      from_date: frmdate,
    });
  };

  handleEndDate = (endate) => {
    this.setState({
      to_date: endate,
    });
  };
  fetchData = () => {
    this.setState({ isLoading: true });
    UserService.getSitesDetails().then(
      (response) => {
        const renObjData = response.data.sites.map((data, index) => {
          return { ...data, id: index };
        });
        this.setState({
          data: renObjData,
          result: renObjData,
          isLoading: false,
        });
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        this.setState({
          data: _content,
          isLoading: false,
        });
      }
    );
    this.setState({ isLoading: true });
  };

  requestSearch = (searchValue) => {
    this.setState({ searchText: searchValue });
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    let data = this.state.data,
      result = [];
    result = data.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field].toString());
      });
    });
    this.setState({ result: result });
  };

  componentDidMount() {
    this.fetchData();
  }

  render() {
    if (!localStorage.getItem("isLoggedIn")) {
      // if true the user will be redirected to /dashboard
      return <Navigate to={{ pathname: "/" }} />;
    }

    const columns = [
      { field: "csiteid", headerName: "Site Code", width: 100 },
      { field: "csitename", headerName: "Site Name", width: 150 },
      { field: "cLocation", headerName: "Location", width: 300 },
      { field: "cRemarks", headerName: "Remark", width: 120 },      
      { field: "DischargeFromToTime", headerName: "Disch. From-To", width: 150 },
      { field: "CommittedM3DayHr", headerName: "Comm. Day/Hr", width: 150 },
      { field: "Zone", headerName: "Zone", width: 70 },
      { field: "make", headerName: "Make", width: 150 },
      { field: "model", headerName: "Model", width: 100 },
      { field: "cPhoneNo", headerName: "Phone", width: 100 },
      { field: "srno", headerName: "SrNo", width: 100 },
      { field: "warr_amc_expdt", headerName: "Warr AMC expdt", width: 100 },
      { field: "conn_sim_or_ip", headerName: "SIM/IP", width: 150 },
      { field: "deact_on", headerName: "Deact On", width: 100 },
      { field: "deact_why", headerName: "Deact WHY", width: 100 },
      { field: "spl_notes", headerName: "Notes", width: 100 }
    ];
    const {
      result,
      searchText,
      isLoading,
    } = this.state;

    // Always a good thing to check if the data is loaded
    return (
      <Container disableGutters maxWidth="false" sx={{ mt: 0, mb: 4 }}>
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <BasicBreadcrumbs name="Site Details" />
        <Grid container spacing={1}>
          {/* Recent Orders */}
          <Grid item xs={12}>
            <div style={{ width: "100%" }}>
              <DataGrid
              density="compact"
                rowHeight={30}
                autoHeight
                getRowId={(result) => result.id}
                rows={result}
                columns={columns}
                pageSize={100}
                rowsPerPageOptions={[100]}
                components={{
                  Toolbar: QuickSearchToolbar,
                }}
                componentsProps={{
                  toolbar: {
                    value: searchText,
                    onChange: (event) => this.requestSearch(event.target.value),
                    clearSearch: () => this.requestSearch(""),
                  },
                }}
              />
            </div>
          </Grid>
        </Grid>
        <Copyright sx={{ pt: 4 }} />
      </Container>
    );
  }
}
